import { WORK_A_SHIFT, WORK_A_TIME_BASED_SHIFT } from '../../constants/globals'
import { CompletionState } from '../../generated/graphql'

const DEFAULT = 'default'

export const GOAL_RESULT_TEXT = {
  [WORK_A_SHIFT]: {
    [CompletionState.Complete]: 'You hit your goal!',
    [CompletionState.Incomplete]: 'Our data shows you didn’t work this day.',
    [CompletionState.AwaitingData]: 'Your goal data is still processing, Check back soon!'
  },
  [WORK_A_TIME_BASED_SHIFT]: {
    [CompletionState.Complete]: 'You hit your goal!',
    [CompletionState.Incomplete]: 'Our data shows you didn’t work this day.',
    [CompletionState.AwaitingData]: 'Your goal data is still processing, Check back soon!'
  },
  [DEFAULT]: {
    [CompletionState.Complete]: 'You hit your goal!',
    [CompletionState.Incomplete]: 'Our data shows you didn’t work this day.',
    [CompletionState.AwaitingData]: 'Your goal data is still processing, Check back soon!'
  }
}

export const GOAL_RESULT_STATUS = {
  [WORK_A_SHIFT]: {
    [CompletionState.Complete]: 'achieved',
    [CompletionState.Incomplete]: 'not_achieved',
    [CompletionState.AwaitingData]: 'processing'
  },
  [WORK_A_TIME_BASED_SHIFT]: {
    [CompletionState.Complete]: 'achieved',
    [CompletionState.Incomplete]: 'not_achieved',
    [CompletionState.AwaitingData]: 'processing'
  },
  [DEFAULT]: {
    [CompletionState.Complete]: 'achieved',
    [CompletionState.Incomplete]: 'not_achieved',
    [CompletionState.AwaitingData]: 'processing'
  }
}

export const getGoalResultText = (goalType: string, state: string): string => {
  if (GOAL_RESULT_TEXT[goalType]) {
    return GOAL_RESULT_TEXT[goalType][state]
  }
  return GOAL_RESULT_TEXT[DEFAULT][state]
}

export const getGoalResultStatus = (goalType: string, state: string): string => {
  if (GOAL_RESULT_TEXT[goalType]) {
    return GOAL_RESULT_STATUS[goalType][state]
  }
  return GOAL_RESULT_STATUS[DEFAULT][state]
}
